@import './variable';
@import './pow';

*:focus {
    outline: none !important;
}

a{
  color: $r1;
  text-decoration: none !important;
  cursor: pointer;

  &:hover{
    text-decoration: none !important;
    color: $r1;
  }
}

.pointer{
  cursor: pointer;
}

.bg{
  background: url('../images/bg5.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: -10;
  min-height: 100vh;
}

.bg-logo{

  @media only screen and (min-width: 960px) {
    background: url('../images/med-icon-half.png') no-repeat right bottom fixed;
    -webkit-background-size: 256px;
    -moz-background-size: 256px;
    -o-background-size: 256px;
    background-size: 256px;
    z-index: -9;
  }
}

@media (min-width: 768px) {
    .navbar-expand-md .navbar-collapse {
        height: auto !important;
    }
}

.navbar-expand-lg{
  transition: none !important;
}
