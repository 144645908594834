// ---------- colors
.sb-red1{
  color: $r1 !important;
}
.sb-red2{
  color: $r2 !important;
}
.sb-red3{
  color: $r3 !important;
}
.sb-red4{
  color: $r4 !important;
}

.sb-blue1{
  color: $b1 !important;
}
.sb-blue2{
  color: $b2 !important;
}
.sb-blue3{
  color: $b3 !important;
}
.sb-blue4{
  color: $b4 !important;
}

.sb-green1{
  color: $g1 !important;
}
.sb-green2{
  color: $g2 !important;
}
.sb-green3{
  color: $g3 !important;
}

.sb-yellow{
  color: $y !important;
}
.sb-purple{
  color: $p !important;
}
.sb-gray{
  color: $g !important;
}

.sb-light{
  color: $l1 !important;
}
.sb-dark{
  color: $d1 !important;
}

// ---------- background
.red{
  background: linear-gradient(336deg, $y, $r2 70%);
}
.blue{
  background: linear-gradient(336deg, $g3, $b4 70%);
}
.green{
  background: linear-gradient(336deg, $b4, $g3 70%);
}
.yellow{
  background: linear-gradient(336deg, $r1, $y 70%);
}
.white{
  background: #ffffffEE !important;
}

// ---------- spacer
.spacer{
  height: 40px;
}

// ---------- typo
.sb-text{

  &.title{
    line-height: 80px;
    //font-size: 5em;
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &.title2{
    line-height: 60px;
    //font-size: 4em;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &.subtitle{
    line-height: 40px;
    //font-size: 3em;
    font-weight: 600;
  }

  &.subtitle2{
    line-height: 30px;
    //font-size: 2em;
    font-weight: 400;
  }

  &.title3{
    margin: auto;
    //font-size: 1.8em;
    font-weight: 600;
    text-transform: uppercase;
  }

  &.subtitle3{
    margin: auto;
    //font-size: 1.5em;
    font-weight: 400;
  }

  &.body{
    //font-size: 1.2em;
    font-weight: 200;
  }

  &.body1{
    font-size: 1.2em;
    font-weight: 200;
  }

  &.light{
    font-weight: 200 !important;
  }

  &.regular{
    font-weight: 400 !important;
  }

  &.bold{
    font-weight: 600 !important;
  }

  &.capitalize{
      text-transform: capitalize !important;
  }

  &.empty{
    -webkit-text-stroke-width: 1px;
    color: transparent;
    -webkit-text-stroke-color: #333;
    -webkit-text-fill-color: transparent;
  }

}

// ---------- surfaces
.sb-papirs{

}

// ---------- btns
.sb-btn-light{
  color: $l1 !important;
  border-color: $l1 !important;
}
.sb-btn-dark{
  color: $d1 !important;
  border-color: $l1 !important;
}
