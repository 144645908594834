/* Color Theme Swatches in Hex */
$r1: #EE6E72;   // primary color
$r2: #FC766A;
$r3: #FAA094;
$r4: #FFBEAD;

$b1: #669DB3;
$b2: #97B3D0;
$b3: #66C0D9;
$b4: #95DBE5;

$g1: #42D4C3;   // secondary color
$g2: #5BD9AF;
$g3: #ADEFD1;   // 69ffed

$y: #FFBE7B;    // yellow
$p: #D76E91;    // purple
$g: #B0B8B4;    // gray

$l1: #FEFEFE;
$d1: #333;

$font: "Roboto", "Helvetica", "Arial", sans-serif;
$font2: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
